<template>
  <b-overlay
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title> Сводные данные за <span class="card-title text-primary">{{ month }}</span></b-card-title>
      </b-card-header>

      <b-card-body>
        <b-col
          lg="4"
          md="4"
        >
          <flat-pickr
            v-model="selectedDate"
            :config="config"
            class="form-control flat-picker bg-transparent"
            placeholder="Выбор даты"
            name="date"
            @on-close="changeDatesRange"
          />
        </b-col>
        <br>
        <b-card-text
          v-if="chosenData.length === 0"
          style="margin-top: 30px;"
        >
          <transition
            mode="out-in"
            name="fade"
          >
            <div class="misc-inner p-2 p-sm-3">
              <div class="w-100 text-center">
                <h2 class="mb-1">
                  Нет данных
                </h2>
                <p class="mb-3">
                  Отсутствует расчет за выбранный день
                </p>
                <b-img
                  :src="downImg"
                  alt=""
                  fluid
                />
              </div>
            </div>
          </transition>
        </b-card-text>
        <transition
          mode="out-in"
          name="fade"
        >
          <b-tabs
            v-if="chosenData.length !== 0"
            fill
            pills
          >
            <b-tab
              v-for="indicator in chosenData"
              :key="Object.keys(indicator)[0]"
              :title="Object.keys(indicator)[0]"
              active
            >
              <b-card-text
                v-if="chosenData.length !== 0"
                style="margin-top: 30px;"
              >
                <div
                  v-for="value in Object.values(indicator)[0]"
                  :key="value"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        :variant="value.avatarVariant"
                      >
                        <feather-icon
                          size="18"
                          :icon="value.avatar"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        {{ value.mode }}
                      </h6>
                      <small>{{ value.types }}</small>
                    </b-media-body>
                  </b-media>
                  <div
                    class="font-weight-bolder"
                    :class="value.deduction ? 'text-danger':'text-success'"
                  >
                    {{ value.payment }}
                  </div>
                </div>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </transition>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BTabs, BCardText, BTab, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { Russian } from 'flatpickr/dist/l10n/ru'
import flatPickr from 'vue-flatpickr-component'

// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

export default {
  components: {
    BTab,
    BCardText,
    BTabs,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    flatPickr,
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
    month: {
      type: String,
      default: 'не установлено',
    },
    indicators: {
      type: Array,
    },
    selectedDate: {
      type: Date,
    },
    showLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      date: new Date(),
      config: {
        locale: Russian,
        maxDate: new Date(),
        dateFormat: 'd.m.Y',
      },
      chosenData: [],
      selected: null,
      transactionData: [],
    }
  },
  computed: {

  },
  watch: {
    data(oldState, newState) {
      this.chosenData = []
      if (this.data.length !== 0) {
        //if (newState == null || oldState !== newState) {
          this.showLoading = true
          this.loadByDate()
       // }
      }
    },
    selectedDate(oldState, newState) {
      if (newState == null || oldState !== newState) {
        this.loadByDate()
      }
    },
  },
  mounted() {
    this.chosenData = []
    this.loadByDate()
  },
  methods: {
    changeDatesRange(selectedDate) {
      this.changeDate = selectedDate
    },
    columnValueFormat(val) {
      // eslint-disable-next-line no-param-reassign
      val = parseFloat(val.replace(/,/g, '.'))

      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      return (val.toFixed(2)).toLocaleString()
    },
    loadByDate() {
      this.chosenData = []
      this.data.forEach(item => {
        const values = Object.values(item)
        if (values.length !== 0) {
          const filteredVal = values[0].filter(c => c.date === +(new Date(this.selectedDate).yyyymmdd()))[0]

          if (filteredVal != null) {
            const dict = {}
            const estimatedData = []

            const planToday = {
              mode: 'План',
              types: 'Формула расчета',
              avatar: 'PocketIcon',
              avatarVariant: 'light-success',
              payment: `${this.columnValueFormat(filteredVal.plan)}`,
              deduction: false,
            }

            const factToday = {
              mode: 'Факт',
              types: 'Формула расчета',
              avatar: 'BarChart2Icon',
              avatarVariant: 'light-success',
              payment: `${this.columnValueFormat(filteredVal.fact)}`,
              deduction: false,
            }

            const completion = {
              mode: 'Выполнение плана',
              types: 'Формула расчета',
              avatar: 'CheckIcon',
              avatarVariant: 'light-success',
              payment: `${this.columnValueFormat(filteredVal.completion)}`,
              deduction: false,
            }

            const requiredDailyBudget = {
              mode: 'Значение, чтобы уложиться в план',
              types: 'Формула расчета',
              avatar: 'TrendingUpIcon',
              avatarVariant: 'light-info',
              payment: `${this.columnValueFormat(filteredVal.requiredDailyBudget)}`,
              deduction: false,
            }

            estimatedData.push(planToday)
            estimatedData.push(factToday)
            estimatedData.push(completion)
            estimatedData.push(requiredDailyBudget)

            dict[Object.keys(item)[0]] = estimatedData
            this.chosenData.push(dict)
          }
        }
      })
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
