<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <div>
          <b-card no-body>
            <b-card-header>
              <div>
                <b-card-title class="mb-1">
                  Выбор месяца
                </b-card-title>
              </div>
            </b-card-header>
            <month-picker
              v-model="selectedMonth"
              lang="ru"
              :default-year="new Date().getFullYear()"
              :default-month="new Date().getMonth() + 1"
              @change="showDate"
            />
          </b-card>
          <b-col cols="12">
            <b-overlay
              :show="showLoadingGraph"
              blur="0"
              opacity=".75"
              rounded="sm"
              spinner-variant="primary"
              variant="white"
            >
              <b-col
                class="mb-1"
                cols="6"
                lg="3"
                md="4"
              >
                <v-select
                  v-model="graph"
                  :options="optionsGraph"
                  :searchable="false"
                  placeholder="Выберите показатель"
                  label="text"
                >
                  <template v-slot:no-options>
                    Ничего не найдено
                  </template>
                </v-select>
              </b-col>
              <vue-apex-charts
                :options="changedChartOptions"
                :series="changedSeries"
                height="400"
                type="area"
              />
            </b-overlay>
          </b-col>
          <b-row>
            <b-col
              lg="6"
              md="6"
            >
              <b-overlay
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
              >
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1">
                        Редактирование плана на <span class="card-title text-primary">{{ date.month }}</span>
                      </b-card-title>
                    </div>
                  </b-card-header>
                  <b-card-body>
                    <b-row>
                      <b-col cols="12">
                        <b-col cols="12">
                          <h6>Плановые показатели</h6>
                          <b-card-text>
                            <span> Установите плановые показатели на месяц </span>
                          </b-card-text>
                          <b-col
                            style="margin-left: -13px; margin-top: 10px;"
                            cols="12"
                          >
                            <b-form-group>
                              <b-form-tags
                                v-model="tagsValue"
                                no-outer-focus
                              >
                                <template v-slot="{ tags, disabled, addTag, removeTag }">
                                  <ul
                                    v-if="tags.length > 0"
                                    class="list-inline d-inline-block mb-1"
                                  >
                                    <li
                                      v-for="tag in tags"
                                      :key="tag"
                                      class="list-inline-item"
                                    >
                                      <b-form-tag
                                        :title="tag"
                                        :disabled="disabled"
                                        variant="primary"
                                        @remove="removeTag(tag)"
                                      >
                                        {{ tag }}
                                      </b-form-tag>
                                    </li>
                                  </ul>

                                  <b-dropdown
                                    size="sm"
                                    variant="outline-secondary"
                                    block
                                    style="z-index: 1000;"
                                    menu-class="w-100"
                                  >
                                    <template v-slot:button-content>
                                      <b-icon icon="tag-fill" /> Выберите показатели
                                    </template>
                                    <b-dropdown-form @submit.stop.prevent="() => {}">
                                      <b-form-group
                                        label-for="tag-search-input"
                                        label="Поиск по показателям"
                                        label-cols-md="auto"
                                        class="mb-0"
                                        label-size="sm"
                                        :description="searchDesc"
                                        :disabled="disabled"
                                      >
                                        <b-form-input
                                          id="tag-search-input"
                                          v-model="search"
                                          type="search"
                                          size="sm"
                                          autocomplete="off"
                                        />
                                      </b-form-group>
                                    </b-dropdown-form>
                                    <b-dropdown-divider />
                                    <b-dropdown-item
                                      v-for="option in availableOptions"
                                      :key="option"
                                      @click="onOptionClick({ option, addTag })"
                                    >
                                      {{ option }}
                                    </b-dropdown-item>
                                    <b-dropdown-text v-if="availableOptions.length === 0">
                                      Не найдено показателей
                                    </b-dropdown-text>
                                  </b-dropdown>
                                </template>
                              </b-form-tags>
                            </b-form-group>
                            <div>
                              <b-dropdown
                                id="choose-kpi-1"
                                :show="showLoading"
                                :disabled="!kpi1DropdownEnabled"
                                variant="outline-primary"
                                aria-role="list"
                                style="margin-right: 5px"
                                :text="((selectedKpi1 == null || selectedKpi1 == '')? 'Выберите KPI 1' : selectedKpi1)"
                              >
                                <b-dropdown-item
                                  v-for="(kpi, index) in kpi1Options"
                                  :key="index"
                                  aria-role="list_item"
                                  @click="setKpi(kpi, 1)"
                                >
                                  {{ kpi }}
                                </b-dropdown-item>
                              </b-dropdown>
                              <b-dropdown
                                id="choose-kpi-2"
                                :show="showLoading"
                                :disabled="!kpi2DropdownEnabled"
                                variant="outline-primary"
                                aria-role="list"
                                style="margin-right: 5px"
                                :text="((selectedKpi2 == null || selectedKpi2 == '')? 'Выберите KPI 2' : selectedKpi2)"
                              >
                                <b-dropdown-item
                                  v-for="(kpi, index) in kpi2Options"
                                  :key="index"
                                  aria-role="list_item"
                                  @click="setKpi(kpi, 2)"
                                >
                                  {{ kpi }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                            <br>
                            <b-modal
                              id="modal-indicator"
                              cancel-variant="outline-secondary"
                              ok-title="Добавить"
                              cancel-title="Закрыть"
                              centered
                              title="Ключевой показатель"
                              @ok="handleOk"
                            >
                              <b-form @submit.stop.prevent="handleSubmit">
                                <b-form-group>
                                  <label for="indicatorValue">Значение ({{ indicatorText }}):</label>
                                  <b-form-input
                                    id="indicatorValue"
                                    v-model="indicatorValue"
                                    type="number"
                                    placeholder="Введите значение показателя"
                                  />
                                </b-form-group>
                              </b-form>
                            </b-modal>
                            <b-alert
                              v-height-fade.appear
                              variant="success"
                              dismissible
                              fade
                              :show="true"
                              class="mb-1"
                            >
                              <div class="alert-body">
                                <span>Не забудьте сохранить после добавления показателей</span>
                              </div>
                            </b-alert>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="mr-1"
                              @click="savePlan"
                            >
                              Сохранить плановые показатели
                            </b-button>
                          </b-col>
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-overlay>
            </b-col>

            <b-col
              lg="6"
              md="6"
            >
              <summary-plan-fact
                :data="planFactEstimates"
                :selected-date="date.from"
                :show-loading="showSummaryLoading"
                :indicators="loadedIndicators"
                :month="date.month"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCol,
  BBadge,
  BTooltip,
  BRow,
  BImg,
  BTabs,
  BTab,
  BFormInput,
  BForm,
  BTableLite,
  BFormSelect,
  BFormTags,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownForm,
  VBToggle, VBTooltip, BAlert, BDropdownDivider, BDropdownItem, BFormTag, BDropdownText, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import flatPickr from 'vue-flatpickr-component'
import { MonthPicker } from 'vue-month-picker'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import _ from 'lodash'
import SummaryPlanFact from './SummaryPlanFact.vue'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

vSelect.props.components.default = () => ({ OpenIndicator })

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series10: '#a9390c',
    series9: '#316b6b',
    series8: '#e26572',
    series7: '#e369b6',
    series6: '#5dd2db',
    series5: '#7253d0',
    series4: '#4f82cf',
    series3: '#8BC554',
    series2: '#FECC35',
    series1: '#FC4526',
  },
}

// eslint-disable-next-line no-extend-native
Date.prototype.yyyymm = function () {
  const mm = this.getMonth() + 1
  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
  ].join('')
}

export default {
  components: {
    MonthPicker,
    BCard,
    BButton,
    BFormCheckbox,
    vSelect,
    SummaryPlanFact,
    BForm,
    BImg,
    BTooltip,
    BTabs,
    BFormTags,
    BTab,
    BFormSelect,
    BCardHeader,
    BTableLite,
    BAlert,
    BDropdown,
    BDropdownForm,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BCardBody,
    BOverlay,
    BCardTitle,
    VueApexCharts,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
  },
  data() {
    return {
      graph: null,
      optionsGraph: [],
      selectedMonth: null,
      showLoadingGraph: false,
      showSummaryLoading: false,
      kpi1DropdownEnabled: false,
      kpi2DropdownEnabled: false,
      selectedKpi1: null,
      selectedKpi2: null,
      loadedIndicators: [],
      tagsValue: [],
      kpi1Options: [],
      kpi2Options: [],
      availableKpi1Options: [
        'Маржинальная прибыль (аналитика)',
        'Маржинальная прибыль (CRM)',
        'Стоимость заказов (аналитика)',
        'Стоимость заказов (CRM)',
        'Roi (аналитика)',
        'Roi (CRM)',
      ],
      availableKpi2Options: [
        'Выручка (аналитика)',
        'Выручка (CRM)',
        'Кол-во заказов (аналитика)',
        'Кол-во заказов (CRM)',
        'Показы',
        'Клики',
      ],
      options: [
        'Показы',
        'Клики',
        'Расходы',
        'Ctr',
        'Средняя стоимость клика',
        'Выручка (аналитика)',
        'Маржинальная прибыль (аналитика)',
        'Кол-во заказов (аналитика)',
        'Стоимость заказов (аналитика)',
        'Cr (аналитика)',
        'Roi (аналитика)',
        'Выручка (CRM)',
        'Маржинальная прибыль (CRM)',
        'Кол-во заказов (CRM)',
        'Стоимость заказов (CRM)',
        'Cr (CRM)',
        'Roi (CRM)',
      ],
      changedSeries: [],
      changedChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [{
          labels: {
            formatter(value) {
              return value
            },
          },
        }],
        fill: {
          opacity: 0.3,
          type: 'solid',
        },
        tooltip: {
          offsetY: 20,
        },
        colors: [
          chartColors.area.series3,
          chartColors.area.series2,
          chartColors.area.series1,
          chartColors.area.series4,
          chartColors.area.series5,
          chartColors.area.series6,
          chartColors.area.series7,
          chartColors.area.series8,
          chartColors.area.series9,
          chartColors.area.series10,
        ],
      },
      maxValueBid: null,
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
      data: [],
      adsLinksError: [],
      resultFilter: null,
      percentValues: ['Roi',
        'Ctr',
        'Cr',
        'Cr (аналитика)',
        'Roi (аналитика)'],
      rubValues: ['Расходы',
        'Средняя стоимость клика',
        'Выручка (аналитика)',
        'Маржинальная прибыль (аналитика)',
        'Стоимость заказов (аналитика)', 'Выручка',
        'Маржинальная прибыль', 'Стоимость заказов'],
      optionsWithProperties: [
        {
          value: 'Success',
          text: 'Успешная проверка',
        },
        {
          value: 'Error',
          text: 'Ошибка 404',
        },
        {
          value: 'HostError',
          text: 'Ошибка хоста',
        },
        {
          value: 'All',
          text: 'Все',
        },
      ],
      columnsVisible: {
        ads_link: { title: 'Ссылка', display: true },
        result: { title: 'Результат проверки', display: true },
      },
      errorLinksTableLoading: false,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      perEffectPage: {
        value: 30,
        text: '30 на странице',
      },
      showLoading: false,
      saveData: [],
      indicatorValue: null,
      indicatorText: null,
      search: '',
      tempOption: null,
      planFactEstimates: [],
      dataForGraphs: {},
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    availableOptions() {
      const { criteria } = this
      const options = this.options.filter(opt => this.tagsValue.indexOf(opt) === -1)
      if (criteria) {
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Нет подходяшего ответа'
      }
      return ''
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Success : 'light-success',
        Error : 'light-danger',
        HostError: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        Success : 'Успешная проверка',
        Error : 'Ошибка 404',
        HostError: 'Ошибка хоста',
      }

      return status => statusLabel[status]
    },
  },
  watch: {
    activeProjectId(oldState, newState) {
      this.data = []
      if (newState == null || oldState !== newState) {
        const formattedDate = new Date(this.date.year, this.date.monthIndex - 1, 1)
        this.monthPlans((+formattedDate.yyyymm()))
      }
    },
    graph(oldState, newState) {
      if (newState == null || oldState !== newState) {
        this.buildGraph()
      }
    },
    selectedMonth(oldState, newState) {
      if (this.activeProjectId) {
        if (newState == null || oldState !== newState) {
          this.planFactEstimates = []
          this.changedSeries = []
          this.tempOption = []
          this.tagsValue = []
          this.kpi1Options = []
          this.kpi2Options = []
          this.changedSeries = []
          this.graph = null
          this.optionsGraph = []
          this.loadedIndicators = []
          const formattedDate = new Date(this.date.year, this.date.monthIndex - 1, 1)
          this.selectedKpi2 = null
          this.selectedKpi1 = null
          this.monthPlans((+formattedDate.yyyymm()))
        }
      }
    },
  },
  mounted() {
    if (this.activeProjectId && this.date.year !== null) {
      const formattedDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      this.monthPlans((+formattedDate.yyyymm()))
    }
  },
  created() {
  },
  methods: {
    percentagePlaceHolder(item) {
      if (item != null) {
        if (this.percentValues.includes(item)) {
          return '%'
        }

        if (this.rubValues.includes(item)) {
          return ' руб.'
        }
        return ''
      }
      return ''
    },
    handleSubmit() {
      this.tempOption.addTag(
        `${this.tempOption.option}=${this.indicatorValue}${this.percentagePlaceHolder(this.tempOption.option)}`,
      )
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-indicator')
        this.indicatorValue = null
        this.tempOption = null
        this.indicatorText = null
      })
    },
    monthPlans(formattedDate) {
      if (formattedDate !== null && formattedDate !== undefined) {
        this.tagsValue = []
        this.planFactEstimates = []
        this.showLoading = true
        this.showLoadingGraph = true
        this.showSummaryLoading = true
        this.$http.get(`planfact/month-plans?date=${formattedDate}&projectId=${this.activeProjectId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // TO-DO: NEED refactoring
            const responseData = response.data

            const listDate = []

            // need change
            // const startDate = '2022-10-01'
            // const endDate = '2022-10-22'

            let startDate = this.date.from
            let endDate = this.date.to
            const today = new Date()
            if (this.date.from > today) {
              startDate = today
            }
            if (this.date.to > today) {
              endDate = today
            }

            startDate = this.formatDateNew(startDate)
            endDate = this.formatDateNew(endDate)

            const dateMove = new Date(startDate)
            let strDate = startDate

            while (strDate < endDate) {
              strDate = dateMove.toISOString().slice(0, 10)
              listDate.push(strDate)
              dateMove.setDate(dateMove.getDate() + 1)
            }

            const dates = listDate
            this.changedChartOptions = {
              ...this.changedChartOptions,
              ...{
                xaxis: {
                  labels: {
                    format: 'dd MMM',
                    formatter(value) {
                      const formatLocaleDate = {
                        month: 'numeric',
                        day: 'numeric',
                      }
                      return new Date(value)
                        .toLocaleDateString('ru', formatLocaleDate)
                    },
                  },
                  categories: dates,
                  tickAmount: Math.round(dates.length / 2),
                },
                tooltip: {
                  x: {
                    formatter(value) {
                      const formatLocaleDate = {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }

                      return new Date(dates[value - 1])
                        .toLocaleDateString('ru', formatLocaleDate)
                    },
                  },
                },
              },
            }

            const planEstimatesPromises = []
            responseData.forEach(item => {
              const { id } = item
              const { keyIndicator } = item
              const keyValue = item.keyIndicatorValue
              const tagItem = `${keyIndicator}=${keyValue}${this.percentagePlaceHolder(keyIndicator)}`
              this.tagsValue.push(tagItem)

              if (this.availableKpi1Options.includes(keyIndicator)) {
                this.kpi1Options.push(keyIndicator)
                if (this.availableKpi1Options.includes(item.selectedKPI1)) {
                  this.selectedKpi1 = item.selectedKPI1
                }
              }
              if (this.availableKpi2Options.includes(keyIndicator)) {
                this.kpi2Options.push(keyIndicator)
                if (this.availableKpi2Options.includes(item.selectedKPI2)) {
                  this.selectedKpi2 = item.selectedKPI2
                }
              }
              this.loadedIndicators.push(keyIndicator)
              planEstimatesPromises.push(this.loadPlanEstimates(id, keyIndicator))
            })

            // настройка выпадающих списков kpi1 и kpi2
            this.kpi1DropdownEnabled = this.kpi1Options.length
            this.kpi2DropdownEnabled = this.kpi2Options.length


            Promise.all(planEstimatesPromises).then(result => {
              this.buildGraph()
            })

            this.showSummaryLoading = false
            this.showLoading = false
            this.showLoadingGraph = false
          })
      }
    },
    formatDate(date) {
      const year = date.toString().substring(0, 4)
      const month = date.toString().substring(4, 6)
      const day = date.toString().substring(6, 8)

      return `${year}-${month}-${day}`
    },
    formatDateNew(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    buildGraph() {
      console.log(this.graph, 'this.graph')
      if (this.graph == null) {
        this.graph = this.optionsGraph[0]
      }
      if (this.graph != null) {
        const dataForChart = this.dataForGraphs[this.graph]

        const dataPlanSeries = dataForChart.map(d => d.plan)
        const dataFactSeries = dataForChart.map(d => d.fact)
        const dataCompletionSeries = dataForChart.map(d => d.completion)
        const dataRequiredDailyBudgetSeries = dataForChart.map(d => d.requiredDailyBudget)

        const dataPlanChart = {
          name: 'План',
          data: dataPlanSeries,
        }

        const dataFactChart = {
          name: 'Факт',
          data: dataFactSeries,
        }

        const dataCompletionChart = {
          name: 'Выполнение плана',
          data: dataCompletionSeries,
        }

        const dataRequiredDailyBudgetChart = {
          name: 'Значение, чтобы уложиться в план',
          data: dataRequiredDailyBudgetSeries,
        }

        this.changedSeries = [
          dataPlanChart,
          dataFactChart,
          dataCompletionChart,
          dataRequiredDailyBudgetChart,
        ]
      }
    },
    async loadPlanEstimates(monthPlanId, keyIndicator) {
      return new Promise((resolve, reject) => {
        this.$http.get(`planfact/plan-facts?monthPlanId=${monthPlanId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // console.log('loadPlanEstimates')
            // TO-DO: NEED refactoring
            const responseData = response.data
            const dict = {}
            dict[keyIndicator] = responseData
            this.optionsGraph.push(keyIndicator)
            const dataForChart = _.values(responseData)
            this.dataForGraphs[keyIndicator] = dataForChart
            this.planFactEstimates.push(dict)
            resolve(response)
          })
      })
    },
    savePlan() {
      if (!this.activeProjectId) {
        this.showMessage('Не выбран активный проект!')
        return
      }

      this.kpi1Options = []
      this.kpi2Options = []
      this.planFactEstimates = []
      this.showLoading = true
      const chosenMonth = new Date(this.date.year, this.date.monthIndex - 1, 1)
      const planModels = []
      this.tagsValue.forEach(item => {
        const indicator = item.replace(/% ?/g, '')
          .replace(/руб./g, '')
          .split('=')
          .map(i => i.trim())

        if (planModels.some(e => e.keyIndicator === indicator[0])) {
          const existedPlanModel = planModels.find(e => e.keyIndicator === indicator[0])
          existedPlanModel.keyIndicatorValue = indicator[1]
        } else {
          planModels.push({
            projectId: this.activeProjectId,
            date: (+chosenMonth.yyyymm()),
            keyIndicator: indicator[0],
            keyIndicatorValue: indicator[1],
          })

          if (this.availableKpi1Options.includes(indicator[0])) {
            this.kpi1Options.push(indicator[0])
          }
          if (this.availableKpi2Options.includes(indicator[0])) {
            this.kpi2Options.push(indicator[0])
          }
        }
      })

      this.kpi1DropdownEnabled = this.kpi1Options.length
      this.kpi2DropdownEnabled = this.kpi2Options.length

      // если не выбрано ни одного планового показателя на форме,
      // то создадим один фиктивный объект PlanModel, в котором содержится projectId
      if (planModels.length === 0) {
        planModels.push({
          projectId: this.activeProjectId,
          date: (+chosenMonth.yyyymm()),
          keyIndicator: '',
          keyIndicatorValue: '',
        })
      }
      this.showMessage('Сохраняем план и расчитываем показатели...')
      this.$http.post('planfact/save-month-plan', planModels).then(() => {
        // для обновления информации в дропдаунах для выбранных kpi1 и kpi2
        this.$http.get(`planfact/selected-kpis?date=${(+chosenMonth.yyyymm())}&projectId=${this.activeProjectId}`)
          .then(response => {
            const responseData = response.data
            this.selectedKpi1 = (responseData.hasKpi1) ? responseData.selectedKpi1 : ''
            this.selectedKpi2 = (responseData.hasKpi2) ? responseData.selectedKpi2 : ''
          })

        this.showMessage('План сохранен')
        this.showLoading = false
      })
    },
    setKpi(kpi, numberOfKpi = 1) {
      this.showLoading = true

      const monthKpiModel = {
        projectId: this.activeProjectId,
        selectedPeriod: +this.date.from.yyyymm(),
        numberOfSelectedKeyIndicator: numberOfKpi,
        keyIndicator: kpi,
      }

      this.$http.post('planfact/set-kpi', monthKpiModel).then(() => {
        this.showMessage(`Kpi#${numberOfKpi} успешно установлен!`)
        if (numberOfKpi === 1) {
          this.selectedKpi1 = kpi
        }
        if (numberOfKpi === 2) {
          this.selectedKpi2 = kpi
        }

        this.$store.commit('statistic/CLEAR_SUMMARY_TABLE_DATA')
        this.$store.commit('statistic/CLEAR_SUMMARY_TABLE_CUSTOM_DATA')
        this.showLoading = false
      })
    },
    showMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CalendarIcon',
          variant: 'success',
        },
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    onOptionClick({ option, addTag }) {
      this.tempOption = { option, addTag }
      this.indicatorText = option
      this.$bvModal.show('modal-indicator')
      this.search = ''
    },
    showDate(date) {
      this.date = date
    },
  },
}
</script>

<style lang="scss" scoped>

.month-picker__container {
  width: 100%!important;
}

.month-picker__container::v-deep .month-picker__month {
  font-family: "Montserrat", Helvetica, Arial, serif;
}

.month-picker__container::v-deep .month-picker__month.selected {
  background-color: #7367f0!important;
  box-shadow: inset 0 0 3px #7367f0!important;
}

  .no-content {
    margin:0 auto;
  }

</style>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
